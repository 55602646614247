import React from "react"

import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionTelefonica from '../components/pages/VerificacionTelefonica'


const VerificacionTelefonicaPage = ({ pageContext, location }) => (

  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <VerificacionTelefonica companyName={pageContext.company} />
      </>
    )}
  />
)

export default VerificacionTelefonicaPage
